.playground {
  border: 1px solid var(--border-emphasis);
  border-radius: var(--radius-lg);

  overflow: hidden;

  &:has(+ div[class*="annotationlist"]) {
    margin-bottom: 0;

    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  & + div[class*="annotationlist"] {
    margin-top: 0;

    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__preview {
    display: flex;
    flex-flow: row wrap;
    gap: var(--spacing-400);
    justify-content: center;
    align-items: center;

    padding: var(--spacing-600);

    counter-reset: numbering;

    &--background {
      background-color: var(--surface-container);
    }

    &--numbered > *:not(:only-child) {
      position: relative;

      margin-bottom: 34px;

      counter-increment: numbering;

      &::after {
        position: absolute;
        left: 50%;
        bottom: -34px;
        content: counter(numbering);

        display: flex;
        justify-content: center;
        align-items: center;

        width: 24px;
        height: 24px;

        color: var(--text-on-inverted);
        font-weight: 500;
        font-size: 12px;

        border-radius: 50%;

        background-color: var(--surface-inverted);

        transform: translateX(-50%);
      }
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: var(--spacing-150) var(--spacing-200) var(--spacing-150);

    color: var(--text-muted);

    border: none;

    background: none !important;

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      color: var(--text-interactive-default-hover);
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;

    width: 100%;

    color: var(--color-grey-500);
    font-size: var(--font-size-small);

    background-color: var(--surface-muted);

    p:first-of-type {
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: var(--spacing-75);
    }
  }

  &__code {
    position: relative;

    display: flex;
    flex-direction: column;
  }

  &__title {
    padding: var(--spacing-150) var(--spacing-200) var(--spacing-50);
  }

  &__caption {
    padding: var(--spacing-150) var(--spacing-200) var(--spacing-50);

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &__source {
    padding: 0;
    padding: var(--spacing-150) var(--spacing-200) var(--spacing-150);

    line-height: 170%;

    border-top: 1px solid var(--border-emphasis);
  }
}
