.wrapper {
  --surface: none;
  --border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 44px;
  max-width: 44px;
  min-height: 44px;
  max-height: 44px;

  border-radius: var(--radius-sm);

  &_sentiment {
    background-color: var(--surface);

    outline: 1px solid var(--border);
    outline-offset: -1px;
  }
}

.preview_color {
  --token: transparent;
  --size: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: var(--size);
  max-width: var(--size);
  min-height: var(--size);
  max-height: var(--size);

  border-radius: var(--radius-pill);

  overflow: hidden;

  &_surface {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";

      width: 100%;
      height: 100%;

      border-radius: var(--radius-pill);

      background-color: var(--token);

      outline: 1px solid rgb(0 0 0 / 10%);
      outline-offset: -1px;
    }
  }

  &_border {
    --size: 36px;

    outline: 4px solid var(--token);
    outline-offset: -4px;
  }

  &_text {
    color: var(--token);
    font-size: larger;
  }

  &_graphic {
    color: var(--token);
  }
}

.preview_spacing {
  position: relative;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  min-width: 100px;
  max-width: 100px;
  min-height: 32px;
  max-height: 32px;

  overflow: hidden;

  &::after {
    position: absolute;
    content: "";

    display: block;

    width: var(--spacing);
    height: 100%;

    border-radius: var(--radius-sm);

    background: var(--surface-interactive-brand);
  }
}

.preview_elevation {
  min-width: 44px;
  max-width: 44px;
  min-height: 44px;
  max-height: 44px;

  border-radius: var(--radius-pill);

  background: var(--surface-container);

  box-shadow: var(--elevation);

  overflow: hidden;
}

.preview_radius {
  min-width: 44px;
  max-width: 44px;
  min-height: 44px;
  max-height: 44px;

  border-radius: var(--radius);

  background: var(--surface-container);

  outline: 1px solid rgb(0 0 0 / 10%);

  overflow: hidden;
}
