.header {
  $this: &;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: var(--header-height);

  &__logo {
    color: initial;
  }

  &__sections {
    display: flex;

    padding: var(--spacing-100);

    border-radius: var(--radius-lg);

    background-color: var(--surface-container);

    box-shadow: var(--elevation-sm);
  }

  &__section {
    display: inline-block;

    margin-right: var(--spacing-50);
    padding: var(--spacing-25) var(--spacing-75);

    color: var(--text-interactive-default);
    font-weight: 500;
    font-size: var(--font-size-regular);
    text-decoration: none;

    border-radius: var(--radius-md);

    background-color: var(--surface-interactive-subtle);

    &--active {
      --surface-interactive-subtle: var(--surface-interactive-strong);
      --text-interactive-default: var(--text-on-inverted);
      --surface-interactive-subtle-hover: var(--surface-interactive-strong-hover);
      --text-interactive-default-hover: var(--text-on-inverted);
      --surface-interactive-subtle-pressed: var(--surface-interactive-strong-pressed);
      --text-interactive-default-pressed: var(--text-on-inverted);

      color: var(--text-on-inverted);

      background-color: var(--surface-interactive-strong);
    }

    &:last-child {
      margin-right: 0;
    }

    &:not([href]) {
      cursor: default;

      opacity: 0.5;
    }

    &[href] {
      &:hover {
        color: var(--text-interactive-default-hover);

        background: var(--surface-interactive-subtle-hover);
      }

      &:focus-visible {
        outline: 2px solid var(--border-interactive-focus);
        outline-offset: 1px;
      }

      &:active {
        color: var(--text-interactive-default-pressed);

        background: var(--surface-interactive-subtle-pressed);
      }
    }
  }

  &__links {
    display: flex;
    gap: var(--spacing-100);
  }
}
