.component_health {
  display: grid;
  gap: var(--spacing-200);

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-100);
  }

  &__tag {
    margin: 0;
  }
}
