.sidebar {
  font-size: var(--font-size-regular);

  ul {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__sections {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-300);
  }

  &__section-title {
    margin-bottom: var(--spacing-100);
    padding-left: var(--spacing-75);

    font-weight: 600;
  }

  &__section-pages {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-25);
  }

  &__page {
    display: flex;
    justify-content: space-between;

    padding: var(--spacing-50) var(--spacing-150);

    color: var(--text-default);
    text-decoration: none;

    border-radius: var(--radius-sm);

    &--active {
      color: var(--text-interactive-default);

      background-color: var(--surface-interactive-subtle);
    }

    &:hover {
      color: var(--text-interactive-default-hover);

      background-color: var(--surface-interactive-subtle-hover);
    }
  }

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;

    overflow: hidden;
  }

  &__tag {
    margin-inline-end: 0;
    margin-left: var(--spacing-100);
  }
}
