.logo {
  display: flex;
  flex-direction: row;
  align-items: center;

  color: var(--text-default);
  font-size: var(--font-size-small);
  line-height: 100%;
  text-decoration: none;

  svg {
    margin-right: var(--spacing-100);
  }
}
