.tokens {
  padding: var(--spacing-300) 0;
}

.token {
  margin-bottom: var(--spacing-100);
  padding-bottom: var(--spacing-100);

  border-bottom: 1px solid var(--border-default);

  &:last-child {
    margin-bottom: 0;

    border-bottom: none;
  }
}

.token_name {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: var(--spacing-50);
  align-items: center;

  color: var(--text-default);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-200);

  code {
    user-select: all;
  }
}

.token_description {
  align-self: flex-end;

  width: 400px;

  color: var(--text-muted);
  font-size: var(--font-size-200);
}
