.annotation_list {
  padding: 0;

  table {
    width: 100%;

    border-collapse: collapse;

    tbody {
      tr {
        font-size: var(--font-size-small);

        border-top: 1px solid var(--border-default);

        &:first-child {
          border-top: none;
        }

        td {
          padding: var(--spacing-100) var(--spacing-300);

          vertical-align: middle;

          p {
            margin: var(--spacing-100) 0;
          }
        }
      }
    }
  }

  &__number {
    max-width: 32px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 24px;
      height: 24px;

      color: var(--text-on-inverted);
      font-weight: 500;
      font-size: 12px;

      border-radius: 50%;

      background-color: var(--surface-inverted);
    }
  }
}
