.inline_code {
  display: inline-flex;
  gap: var(--spacing-100);

  padding: 0 var(--spacing-50);

  font-family: var(--font-mono);
  text-align: left;

  border: none;
  border-radius: var(--radius-xs);

  background: var(--surface-muted);

  outline: var(--spacing-25) solid var(--surface-muted);

  &--copy {
    cursor: pointer;
  }

  &--wrap {
    word-break: break-all;
  }
}
