.box {
  padding: var(--spacing-300);

  border-radius: var(--radius-lg);

  background-color: var(--surface-container);

  box-shadow: var(--elevation-sm);

  overflow: hidden;

  &--noPadding {
    padding: 0;
  }

  &--noShadow {
    border: 1px solid var(--border-emphasis);

    box-shadow: none;
  }

  &--sentiment-info {
    color: var(--text-on-informative);

    border: 1px solid var(--border-informative);

    background-color: var(--surface-informative-muted);
  }

  &--sentiment-warning {
    color: var(--text-on-attentive);

    border: 1px solid var(--border-attentive);

    background-color: var(--surface-attentive-muted);
  }

  &--transparent {
    background-color: transparent;
  }
}
