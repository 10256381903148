.callout {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  align-items: baseline;

  width: 100%;
  padding: var(--spacing-200);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title,
  &__body {
    max-width: 681px;
  }
}
