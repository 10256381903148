@import "@aidnas/design-system/styles.css";

html {
  --font-size-large: 20px;
  --font-size-regular: 16px;
  --font-size-small: 14px;
  --header-height: 42px;
  --max-content-width: 840px;

  background-color: var(--background-default);
}

body {
  color: var(--text-default);
}

/* stylelint-disable-next-line selector-class-pattern */
.ant-table-cell {
  align-content: flex-start;

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

code {
  padding: 0 4px;

  font-size: 90%;
  white-space: nowrap;

  border: 1px solid var(--border-default);
  border-radius: var(--radius-sm);

  background: var(--surface-container);
}

a {
  color: var(--text-interactive-accent);
  text-decoration: underline;

  &:hover {
    color: var(--text-interactive-accent-hover);
  }

  &:active {
    color: var(--text-interactive-accent-pressed);
  }
}
