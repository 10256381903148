.columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-300);

  &__col {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;

    & > *:only-child {
      height: 100%;
    }
  }
}
