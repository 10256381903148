.prose {
  $this: &;

  display: block;

  &__divider {
    margin: var(--spacing-600) 0;
  }

  &__p {
    max-width: var(--readability-max-width);
    margin: 0.25em 0 0.75em;

    text-wrap: pretty;

    & + #{$this}__p {
      margin-top: 1em;
    }
  }

  &__h1:not(:first-child),
  &__h2:not(:first-child),
  &__h3:not(:first-child),
  &__h4:not(:first-child),
  &__h5:not(:first-child) {
    padding-top: var(--spacing-400);
  }

  ul,
  ol {
    max-width: var(--readability-max-width);
  }

  li {
    margin-left: var(--spacing-50);
    padding: var(--spacing-50) var(--spacing-100);
  }
}
