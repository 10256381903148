.image_block {
  display: flex;
  flex-direction: column;

  &--variant-do {
    color: var(--text-on-positive);

    border: 1px solid var(--border-positive);

    figcaption {
      border-top: 1px solid var(--border-positive);

      background: var(--surface-positive-muted);
    }
  }

  &--variant-dont {
    color: var(--text-on-negative);

    border: 1px solid var(--border-negative);

    figcaption {
      border-top: 1px solid var(--border-negative);

      background: var(--surface-negative-muted);
    }
  }

  &--variant-attention {
    color: var(--text-on-attentive);

    border: 1px solid var(--border-attentive);

    figcaption {
      border-top: 1px solid var(--border-attentive);

      background: var(--surface-attentive-muted);
    }
  }

  &:has(+ div[class*="annotationlist"]) {
    margin-bottom: 0;

    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  & + div[class*="annotationlist"] {
    margin-top: 0;

    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__image_wrapper {
    position: relative;

    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    &--top_padding {
      padding-top: var(--spacing-600);
    }

    &--bottom_padding {
      padding-bottom: var(--spacing-600);
    }
  }

  &__figure {
    display: flex;
    flex-direction: column;

    height: 100%;
    margin: 0;
  }

  &__image {
    margin: 0 var(--spacing-200);

    line-height: 0;
    text-align: center;

    &--top_padding {
      margin-top: var(--spacing-600);
    }

    &--bottom_padding {
      margin-bottom: var(--spacing-600);
    }

    img {
      height: auto;
      max-width: 100%;

      object-fit: scale-down;
    }
  }

  &__diagonal {
    position: absolute;
    inset: 0;

    width: 100%;
    height: 100%;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-100);

    padding: var(--spacing-150);

    color: var(--color-grey-500);
    font-size: var(--font-size-small);

    background-color: var(--surface-muted);

    p:first-of-type {
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
