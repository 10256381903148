.layout {
  $this: &;
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1440px;
  min-height: 100vh;
  margin: 0 auto;

  &__header {
    margin: var(--spacing-300);
  }

  &__body {
    display: flex;
    flex-grow: 1;
    gap: var(--spacing-300);

    width: 100%;
    padding: var(--spacing-300);

    &--center {
      align-items: center;
    }
  }

  &__main {
    flex-grow: 1;
  }

  &__sidebar + &__main {
    #{$this}__content {
      max-width: var(--max-content-width);
    }
  }

  &__content {
    margin: 0 auto;

    &--center {
      text-align: center;
    }
  }

  &__sidebar {
    width: 260px;
  }
}
