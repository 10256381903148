.system_icons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  width: 100%;
  max-width: 100%;

  border: 0.5px solid var(--border-default);

  &__item {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-100);
    justify-content: center;
    align-items: center;

    width: auto;

    border: 0.5px solid var(--border-default);
    aspect-ratio: 3 / 2;
  }

  &__name {
    max-width: 160px;

    white-space: nowrap;
    text-overflow: ellipsis;

    overflow: hidden;

    user-select: all;
  }
}
