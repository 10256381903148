.doc_tabs {
  display: flex;

  &__content {
    // Can't use flex-gap here because some blocks need different spacing
    & > * {
      margin-top: var(--spacing-200);
    }

    > :first-child {
      margin-top: 0;
    }
  }
}
