.changelog {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);

  margin: var(--spacing-300) 0;

  &__notes {
    ul,
    ol {
      padding-left: var(--spacing-200);

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
