.box {
  padding: 0;

  table {
    width: 100%;

    font-size: 14px;

    border-collapse: collapse;

    tr {
      &:not(:last-child) {
        border-bottom: 1px solid var(--border-default);
      }

      td {
        padding: var(--spacing-200) 0 var(--spacing-200) var(--spacing-300);

        vertical-align: middle;

        &:nth-of-type(1),
        &:nth-of-type(2) {
          width: 0;
        }

        &:last-of-type {
          padding-right: var(--spacing-300);
        }

        h4 {
          margin: 0;

          font-weight: 600;
          font-size: inherit;
        }

        p {
          margin: 0;

          font-size: inherit;
        }

        strong {
          font-weight: 600;
        }
      }
    }
  }
}
