.link_block {
  max-width: var(--readability-max-width);

  border: 1px solid var(--border-interactive-on-neutral-subtle);
  border-radius: var(--radius-md);

  &:hover {
    border: 1px solid var(--border-interactive-on-neutral-subtle-hover);
  }

  &:active {
    border: 1px solid var(--border-interactive-on-neutral-subtle-pressed);
  }

  &__link {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-150);
    align-items: center;

    padding: var(--spacing-150);

    text-decoration: none !important;

    border-radius: var(--radius-sm);
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
