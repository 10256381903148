.component_props {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);

  margin-top: var(--spacing-300);

  &__color {
    width: 100px;
    height: 50px;

    border-radius: var(--radius-md);
  }

  &__code {
    margin: 0 calc(var(--spacing-50) * -1);
    padding: var(--spacing-25) var(--spacing-50);

    font-size: var(--font-size-small);
    font-family: var(--font-mono);
    white-space: nowrap;
    text-align: left;

    border: none;

    background: var(--surface-muted);

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    * {
      font-size: var(--font-size-small);
      font-family: var(--font-mono);
    }
  }

  &__type {
    margin: var(--spacing-200) 0;
  }

  &__params-title {
    margin-top: var(--spacing-200);

    font-weight: 600;
  }

  &__params-list {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--spacing-100);

    &:last-child {
      margin-bottom: 0;
    }

    dt,
    dd {
      margin: 0;
      padding: 0;
    }
  }

  &__doc-keyword {
    * + & {
      margin-top: var(--spacing-100);
    }
  }
}
